import { memo, useState, useRef } from 'react'
import Select from 'react-select'

const OtherAreaStep = (props) => {

	const [options, ] = useState([
		{
			name: 'Airplace Accidents',
			value: 'https://www.hakiminjurylaw.com/airplane-accidents',
		},
		{
			name: 'Bicycle Accidents',
			value: 'https://www.hakiminjurylaw.com/bicycle-accident-attorneys',
		},
		{
			name: 'Camp Lejeune Claims',
			value: 'https://camplejeune.hakiminjurylaw.com/tragedy?_gl=1*25erg0*_gcl_aw*R0NMLjE3MTUyNDA5NDQuUm9uYWtzaGFo*_gcl_au*MTY4NjI0NjI3Ny4xNzExMjkyMTY0',
		},
		{
			name: 'Construction Accidents',
			value: 'https://www.hakiminjurylaw.com/construction-accident-lawyers/'
		},
		{
			name: 'Medical Malpractice',
			value: 'https://www.hakiminjurylaw.com/medical-malpractice-attorneys/'
		},
		{
			name: 'Motorcycle Accident',
			value: 'https://www.hakiminjurylaw.com/motorcycle-accident-attorneys/'
		},
		{
			name: 'Premises Liability',
			value: 'https://www.hakiminjurylaw.com/premises-liability-lawyer-attorneys/'
		},
		{
			name: 'Self Driving Car Accidents',
			value: 'https://www.hakiminjurylaw.com/self-driving-car-accidents/'
		},
		{
			name: 'Spinal Cord Injury',
			value: 'https://www.hakiminjurylaw.com/spinal-cord-injury-lawyer/'
		},
		{
			name: 'Truck Accident',
			value: 'https://www.hakiminjurylaw.com/truck-accident-attorneys/'
		},
		{
			name: 'Workplace Accident',
			value: 'https://www.hakiminjurylaw.com/workplace-accident-lawyers/'
		},
		{
			name: 'Amusement Park Accidents',
			value: 'https://www.hakiminjurylaw.com/amusement-park-accidents/'
		},
		{
			name: 'Boating Accidents',
			value: 'https://www.hakiminjurylaw.com/boating-accident-attorneys/'
		},
		{
			name: 'Car Accidents',
			value: 'https://www.hakiminjurylaw.com/car-accidents/'
		},
		{
			name: 'Dog Bites Injuries',
			value: 'https://www.hakiminjurylaw.com/dog-bite-attorney-animal-attacks-lawyer/'
		},
		{
			name: 'Legal Malpractice',
			value: 'https://www.hakiminjurylaw.com/legal-malpractice-attorneys/'
		},
		{
			name: 'Pedestrian Accident',
			value: 'https://www.hakiminjurylaw.com/pedestrian-accident-attorneys-california/'
		},
		{
			name: 'Product Liability Lawyer',
			value: 'https://www.hakiminjurylaw.com/product-liability-attorneys/'
		},
		{
			name: 'Sexual Abuse',
			value: 'https://www.hakiminjurylaw.com/los-angeles-sexual-abuse-attorney/'
		},
		{
			name: 'Swimming Pool Accident',
			value: 'https://www.hakiminjurylaw.com/swimming-pool-accident-attorney/'
		},
		{
			name: 'Uninsured Motorist Accident',
			value: 'https://www.hakiminjurylaw.com/uninsured-motorist-accident-attorneys/'
		},
		{
			name: 'Assault and Battery',
			value: 'https://www.hakiminjurylaw.com/assault-and-battery/'
		},
		{
			name: 'Bus Accident',
			value: 'https://www.hakiminjurylaw.com/bus-accident-attorney/'
		},
		{
			name: 'Catastrophic Injuries',
			value: 'https://www.hakiminjurylaw.com/catastrophic-injuries/'
		},
		{
			name: 'Fireworks Accident',
			value: 'https://www.hakiminjurylaw.com/fireworks-accident-attorney/'
		},
		{
			name: 'Lyft and Uber Rideshare Accident',
			value: 'https://www.hakiminjurylaw.com/lyft-and-uber-rideshare-accident-attorneys/'
		},
		{
			name: 'Personal Injury',
			value: 'https://www.hakiminjurylaw.com/personal-injury-attorneys/'
		},
		{
			name: 'Taxi Accident',
			value: 'https://www.hakiminjurylaw.com/rideshare-taxi-accident-attorneys/'
		},
		{
			name: 'Train Accident',
			value: 'https://www.hakiminjurylaw.com/train-accident-injuries/'
		},
		{
			name: 'Window Fall Accident',
			value: 'https://www.hakiminjurylaw.com/window-fall-accident-lawyers/'
		},
		{
			name: 'Wrongful Death',
			value: 'https://www.hakiminjurylaw.com/wrongful-death-lawyers/'
		},

	])
	const selRef = useRef()

	// Custom styles for the Select component
	const customStyles = {
		control: (provided) => ({
			...provided,
	      border: '1px solid #e2e8f0', // Border color
	      borderRadius: '4px',
	      boxShadow: 'none',
	      Padding:"20px"
	  }),
		menu: (provided) => ({
			...provided,
			backgroundColor: '#fff', 
			Padding:"20px"
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? '#fff8dc' : 'transparent',
	      border: state.isFocused ? '1px solid #CDA716' : '1px solid transparent', // Border when option is focused
	      color: state.isSelected ? '#333' : '#333', 
	      fontSize: state.isSelected ? '12px' : '12px', 

	  }),
	}

	const getOptionLabel = (item) => item.name
	const redirectAreaOfLaw = (item) => {
		window.top.location.href = item.value
	}

	return (

		<div>
			<div className='block'>
				<p className='font-normal text-base leading-7 mb-4'>Which Other Law Area you are looking for?</p>

				<div className="App">
					<div className='form_select_2'>
						<Select ref={selRef} name='other_law_area' defaultValue='' options={options} getOptionLabel={getOptionLabel} onChange={redirectAreaOfLaw} className="focus:outline-none" styles={customStyles} placeholder="Choose the Area of Law" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default memo(OtherAreaStep)