import { memo } from 'react'
// import { Line } from 'rc-progress'
import "react-step-progress-bar/styles.css"
import { ProgressBar } from "react-step-progress-bar"

const CustomProgressBar = (props) => {

	const { percent, height } = props
	return (
		<div className='mb-6 relative w-full h-5'>

			<ProgressBar percent={percent} filledBackground="linear-gradient(to right, #cda716, #dfd384)" height={height} />

			<div className='text-xs font-medium !text-white' style={{ textAlign: "center", width: `${percent}%`, position: 'absolute', top: '44%', transform: 'translateY(-50%)', color: '#000' }}>
				{`${percent}%`}
			</div>

			{/*<Line percent={percent} strokeWidth={1} strokeColor="#CDA716"   className='w-full h-4 rounded-full ' />
			<div className='text-xs font-medium	!text-white' style={{ textAlign: "center", width: `${percent}%`, position: 'absolute', top: '44%', transform: 'translateY(-50%)', color: '#000' }}>
				{`${percent}%`}
      		</div>*/}
		</div>
	)
}

export default memo(CustomProgressBar)