import axios from 'axios'

class ScreeningService {

	getCaseQuestions(payload) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/screening/`, {
				params: {
					...payload,
				}
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	verifyCase(payload) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/screening/verify_case/`, {
				...payload
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	contactAdmin(id, message) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/screening/send_message/`, {
				id,
				message,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	signupLead(payload) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/screening/signup/`, {
				params: {
					...payload,
				}
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	trackAnalytics(payload) {
		return new Promise((resolve, reject) => {
			axios.post(`/api/screening/track_analytics/`, {
				...payload
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

}
const screeningService = new ScreeningService()

export default screeningService