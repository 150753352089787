import { ScreeningProvider } from 'context'
import Screening from 'containers/screeningContainer'

export const routes = [
	{
		path: '/',
		element: (
			<ScreeningProvider>
				<Screening />
			</ScreeningProvider>
		),
	},
]
