import { useEffect } from 'react'
import { Routes, Route } from "react-router-dom"
import { routes } from "routes"
import ReactGA from 'react-ga4'

const App = () => {

  useEffect( () => {
    if(process.env.REACT_APP_APP_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    } 
  }, [])

  return (
    <>
      <Routes>
        {routes.map(item => {
          return <Route path={item.path} element={item.element} key={item.path} />
        })}
      </Routes>
    </>
  )
}

export default App
