import { connect } from 'react-redux'
import Screening from 'pages/screeningv2'
import { getCaseQuestions, verifyCase, contactAdmin, signupLead } from 'actions'

const mapStateToProps = (state) => {
   return {
      ...state.screeningReducer,
   };
};

export default connect(mapStateToProps, { getCaseQuestions, verifyCase, contactAdmin, signupLead })(Screening)