import { memo } from 'react'
import Logo1 from 'assets/img/logo1.png'
import Logo2 from 'assets/img/logo2.png'
import Logo3 from 'assets/img/logo3.png'
import Logo4 from 'assets/img/logo_5.png'

const Badges = (props) => {

	const badges = [
		{
			name: Logo1,
		},
		{
			name: Logo2,
		},
		{
			name: Logo3,
		},
		{
			name: Logo4,
		},
	]

	return (
		<>
			<div className="my-8">
				<ul className="max-w-[580px] m-auto flex flex-wrap sm:flex-nowrap items-center justify-center md:justify-between">
					{badges.map((item, index) => (
						<li className="p-4" key={index}>
							<div className="text-center">
								<img src={item.name} className="m-auto max-w-[140px] h-auto" alt="logo-img" />
							</div>
						</li>
					))}
				</ul>
			</div>
		</>
	)
}

export default memo(Badges)