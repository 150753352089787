import * as Yup from "yup"
import "yup-phone"

export const validationSchema = Yup.object().shape({
	first_name: Yup.string().required("Please Enter Your First Name"),
	last_name: Yup.string().required("Please Enter Your Last Name"),
	email: Yup.string().required("Please Enter Your Email Address").email("Email must be valid"),
	// phone: Yup.string().required("Please Enter Your Contact Number"),
	phone: Yup.string().required("Please Enter Your Contact Number").matches(/^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/, {
		message: "Invalid phone number",
       excludeEmptyString: false,
	}),
	accident_date: Yup.date().required("Please Enter Date of Accident"),
	// accident_date: Yup.date("Please Enter Date of Accident").notRequired(),
	state: Yup.string().required("Please Select State"),
})