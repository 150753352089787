const initialState = {
	questions: [],
	states: [],
	lead: {},
	signup_url: null,
	qualifiedContent: {
		title: '',
		message: '',
	},
	disqualifiedContent: {
		title: '',
		message: '',
	},
	holdContent: {
		title: '',
		message: '',
	},

}

const screeningReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_CASE_QUESTIONNAIRE':
			const questions = action.payload.questions.map(item => {
				item.value = ''
				return { ...item }
			})

			const qualifiedSetting = action.payload.settings.filter(item => item.type === 'qualified')
			const disqualifiedSetting = action.payload.settings.filter(item => item.type === 'disqualified')
			const holdSetting = action.payload.settings.filter(item => item.type === 'hold')

			const qualifiedContent = {
				title: qualifiedSetting.filter(item => item.name === 'title').pop().value,
				message: qualifiedSetting.filter(item => item.name === 'message').pop().value
			}

			const disqualifiedContent = {
				title: disqualifiedSetting.filter(item => item.name === 'title').pop().value,
				message: disqualifiedSetting.filter(item => item.name === 'message').pop().value
			}

			const holdContent = {
				title: holdSetting.filter(item => item.name === 'title').pop().value,
				message: holdSetting.filter(item => item.name === 'message').pop().value
			}
			
			return {
				...state,
				questions: [...questions],
				states: [...action.payload.states],
				qualifiedContent: { ...qualifiedContent },
				disqualifiedContent: { ...disqualifiedContent },
				holdContent: { ...holdContent },
			}

		case 'SET_LEAD_INFO':
			return {
				...state,
				lead: { ...action.payload.lead },
				signup_url: action.payload.signup_url,
			}

		default:
			return { ...state }

	}
}

export default screeningReducer