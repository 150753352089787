import { memo } from 'react'
// import BadgeSlider from './components/BadgeSlider'
import Badges from './components/Badges'

const Layout = ({children, showLastStep, showResult}) => {

	return (
		<>
			<div className='w-full flex items-center justify-center py-8 px-4'>
				<div className='w-full max-w-[924px]'>
					{ children }
				</div>
			</div>
			{/*<BadgeSlider />*/}
			
			{(!showLastStep && !showResult) &&
				<Badges />
			}
		</>
	)		
}

export default memo(Layout)