import { memo } from 'react'
import LeftArrow from "assets/svg/left-arrow.svg"
import RefreshIcon from "assets/svg/refresh-icon.svg"

const ResetBtnGroup = (props) => {

	const { step, changeStep, showResult, restartScreening, showOtherAreaStep, terminateScreening } = props

	return (
		<div className='flex gap-3 mb-2'>
			{!showOtherAreaStep && step > 1 &&
				<>
					{!showResult &&
						<button onClick={() => changeStep(step - 1)} className='bg-secondary text-white w-6 md:w-8 max-w-[30px] h-6 md:h-8 max-h-[30px] flex items-center justify-center'><img src={LeftArrow} alt="arrow" className="h-auto w-5" /> </button>
					}

					<button onClick={restartScreening} className='bg-secondary text-white w-6 md:w-8 max-w-[30px] h-6 md:h-8 max-h-[30px] flex items-center justify-center'><img src={RefreshIcon} alt="arrow" className="h-auto w-5" /> </button>
				</>
			}
			{(showOtherAreaStep || terminateScreening) &&
				<button onClick={restartScreening} className='bg-secondary text-white w-6 md:w-8 max-w-[30px] h-6 md:h-8 max-h-[30px] flex items-center justify-center'><img src={RefreshIcon} alt="arrow" className="h-auto w-5" /> </button>	
			}
		</div>
	)
}

export default memo(ResetBtnGroup)