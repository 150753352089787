import { memo, useState } from 'react'
import { Modal } from 'react-responsive-modal'
// import CloseIcon from 'assets/svg/close.svg'
import 'react-responsive-modal/styles.css'

const MessageModal = (props) => {
	const { show, handleClose, sendMessage, notifyMessageSent } = props
	const [message, setMessage] = useState('')
	const handleChange = (e) => setMessage(e.target.value)
	const sendMessageToAdmin = () => {
		if(message.trim() !== '') {
			
			sendMessage(message).then(response => {
				if(response.data.success) {
					setMessage('')
					notifyMessageSent()
				}
			}).catch(err => console.log(err))
		}
	}
	
	return (
		<>
			<Modal open={show} onClose={handleClose} center classNames={{ overlay: 'customOverlay', modal: 'message-modal  w-72 md:w-96 lg:w-full' }}>
				<div className='py-4 lg:py-12 px-4 lg:px-16'>
					<div className="mb-2.5">
						
						<label htmlFor="textarea" className="block text-base font-medium text-black-200">Please Enter Your Message</label>
						
						<div className="mt-1">
							<textarea id="message" name="message" value={message} onChange={handleChange} rows={3} className="resize-none bg-white border border-border1 focus:ring-indigo-500 focus:border-border1 block w-full sm:text-sm py-4 px-3" placeholder="Type your message..." />
						</div>
					</div>

					<div className='block text-end'>
						<button type="button" disabled={!message.trim()} onClick={() => sendMessageToAdmin()} className='next-btn py-[14px] px-5 font-medium text-base leading-5 text-white'>Submit</button>
					</div>
				</div>	
			</Modal>
		</>
	)
}

export default memo(MessageModal)