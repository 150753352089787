import { combineReducers } from 'redux'
import screeningReducer from './screeningReducer'

const initialState = {
	app_name: 'Law Offices of Eslamboly Hakim',
	show_loader: false,
}

const reducer = (state = initialState, action) => {
	return {
		...state,
	}
}

const rootReducer = combineReducers({reducer, screeningReducer })

export default rootReducer